import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  persistence: 'memory',
    bootstrap: {
      distinctID: 'user distinct id',
      featureFlags: {
        'feature-flag-1': true,
        'feature-flag-2': false,
      },
    },
}

ReactDOM.render(
  <BrowserRouter>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </PostHogProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
